import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import Panel from '../../components/Tabs/Panel';
import Tabs from '../../components/Tabs/Tabs';
import Spinner from '../../components/UI/Spinner/Spinner';
import activitiesService from '../../services/activitiesService';
import activityReviewService from '../../services/activityReviewService';
import AcitvityDetails from '../activity-details';
import ActivityPracticalInfo from '../activity-practical-info';
import BookButton from '../../components/BookButton/BookButton';

import styles from './index.module.scss';
import BreadcrumbsActivity from '../../components/BreadcrumbsActivity/BreadcrumbsActivity';
import { ACTIVITY_BOOK_BUTTON } from '../../constans/texts/texts';
import { cleanedUrl } from '../../constans/locations';

const tabLabels = ['Review', 'Practical info'];

function ActivityPage({
  id,
  tabsClassName,
  switchersClassName,
  isSearchResult,
  goBackButton,
  buttonsContainerClasses = '',
  bookActivityButtonWrapperClasses = '',
}) {
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getDetails = async (activityId) => {
    setIsLoading(true);

    const reviewsSerivce = activityReviewService();
    const { data } = await activitiesService().getActiityDetails(activityId);
    const { activityReview } = data;

    const [{ data: pricing }, { data: timing }] = await Promise.all([
      reviewsSerivce.getActivityReviewPricing(activityReview?.id),
      reviewsSerivce.getActivityReviewTimings(activityReview?.id),
    ]);

    setTimeout(() => setIsLoading(false), 100);
    setDetails({
      ...data,
      pricing,
      timing,
    });
  };

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (details && !isSearchResult) {
      const cityPart = cleanedUrl(`${details?.urlDestination || details?.city?.name || details?.region?.name || details?.country?.name}`);
      const activityPart = cleanedUrl(`${details?.urlName || details?.title}`);

      navigate(`/activities/${cityPart}/${activityPart}`);
    }
  }, [details, isSearchResult]);

  if (isLoading) {
    return <Spinner spinnerClasses={styles.spinner} />;
  }

  return (
    <>
      {!isSearchResult && (
      <BreadcrumbsActivity
        country={details.country}
        region={details.region}
        city={details.city}
        district={details.district}
        name={details.title}
      />
      )}
      <div className={`${buttonsContainerClasses || styles.headerButtonsContainer}`}>
        {goBackButton}
        <BookButton
          details={details}
          buttonWrapperClasses={
            bookActivityButtonWrapperClasses || styles.bookActivityButtonWrapper
          }
          text={ACTIVITY_BOOK_BUTTON}
        />
      </div>
      <Tabs
        switchersClassName={`${styles.tabsSwitchers} ${switchersClassName}`}
        className={`${styles.tabs} ${tabsClassName}`}
        switchers={tabLabels}
      >
        <Panel tabId={0}>
          <AcitvityDetails id={id} details={details} />
        </Panel>
        <Panel tabId={1}>
          <ActivityPracticalInfo isSearchResult={isSearchResult} details={details} />
        </Panel>
      </Tabs>
    </>
  );
}

export default ActivityPage;
